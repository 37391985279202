import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, NavLink, Navigate } from 'react-router-dom';
import WagerList from './components/WagerList';
import WagerForm from './components/WagerForm';
import Register from './components/Register';
import Login from './components/Login';
import Logout from './components/Logout';
import pb from './pb';

function App() {
  const [user, setUser] = useState(null);
  const [wagers, setWagers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showForm, setShowForm] = useState(false); // Toggle WagerForm modal

  // Initialize user on app load
  useEffect(() => {
    const currentUser = pb.authStore.model;
    setUser(currentUser);
    setLoading(false);
  }, []);

  // Listen for authentication changes
  useEffect(() => {
    const unsubscribe = pb.authStore.onChange(() => {
      const currentUser = pb.authStore.model;
      setUser(currentUser);
    });
    return () => unsubscribe();
  }, []);

  // Fetch wagers when user is authenticated
  useEffect(() => {
    if (user) {
      const fetchWagers = async () => {
        try {
          const records = await pb.collection('wagers').getFullList({
            expand: 'participants,createdby',
          });
          setWagers(records);
        } catch (error) {
          console.error("Error fetching wagers:", error);
        }
      };
      fetchWagers();
    }
  }, [user]);

  const addWager = async (wager) => {
    try {
      const record = await pb.collection('wagers').create(wager);
      setWagers((prev) => [...prev, record]);
    } catch (error) {
      console.error('Error adding wager:', error);
    }
  };

  const updateWager = async (id, updatedData) => {
    try {
      console.log(id);
      console.log(updatedData);
      const updatedWager = await pb.collection("wagers").update(id, updatedData);
      setWagers((prev) =>
        prev.map((wager) => (wager.id === id ? updatedWager : wager))
      );
    } catch (error) {
      console.error("Error updating wager:", error);
    }
  };

  const toggleFormModal = () => setShowForm((prev) => !prev);

  if (loading) {
    return <p>Loading...</p>;
  }

  return (
    <Router>
      <div className="App">
        {/* Navbar */}
        {user && (
          <nav className="navbar navbar-expand-lg navbar-light bg-light">
            <div className="container-fluid">
              <NavLink to="/" className="navbar-brand">
                Bet My Life
              </NavLink>
              <div className="collapse navbar-collapse">
                <ul className="navbar-nav me-auto">
                  <li className="nav-item">
                    <NavLink to="/" className="nav-link" activeClassName="active">
                      Wager List
                    </NavLink>
                  </li>
                </ul>
              </div>
              <div className="d-flex">
                <button className="btn btn-primary me-2" onClick={toggleFormModal}>
                  Create Wager
                </button>
                <Logout onLogout={() => setUser(null)} />
              </div>
            </div>
          </nav>
        )}

        {/* Routes */}
        <div className="container mt-4">
          <Routes>
            {/* Public Routes */}
            {!user && (
              <>
                <Route path="/login" element={<Login onLogin={(authData) => setUser(authData.record)} />} />
                <Route path="/register" element={<Register />} />
                <Route path="*" element={<Navigate to="/login" replace />} />
              </>
            )}

            {/* Private Routes */}
            {user && (
              <>
                <Route path="/" element={<WagerList wagers={wagers} updateWager={updateWager} />} />
              </>
            )}
          </Routes>
        </div>

        {/* Modal for WagerForm */}
        {showForm && (
          <div className="modal show d-block" style={{ backgroundColor: 'rgba(0,0,0,0.5)' }}>
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">Create a Wager</h5>
                  <button type="button" className="btn-close" onClick={toggleFormModal}></button>
                </div>
                <div className="modal-body">
                  <WagerForm addWager={addWager} onClose={toggleFormModal}/>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </Router>
  );
}

export default App;
