import React, { useState, useEffect, useCallback } from 'react';
import pb from '../pb';

function WagerForm({ addWager, onClose }) {
  const [form, setForm] = useState({
    title: '',
    participants: [],
    status: 'Pending',
    terms: '',
    deadline: '',
    stakes: '',
    outcome: '',
  });
  const [errors, setErrors] = useState({});
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);

  const validateForm = useCallback(() => {
    const newErrors = {};
    if (!form.title) newErrors.title = 'Title is required.';
    if (!form.participants.length) newErrors.participants = 'Participants are required.';
    if (!form.status) newErrors.status = 'Status is required.';
    if (!form.terms) newErrors.terms = 'Terms are required.';
    if (!form.deadline) newErrors.deadline = 'Deadline is required.';
    if (!form.stakes) newErrors.stakes = 'Stakes are required.';
    if (!form.outcome) newErrors.outcome = 'Outcome is required.';
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  }, [form]); // Include form as a dependency

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const userList = await pb.collection('users').getFullList();
        setUsers(userList);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    };
    fetchUsers();
  }, []);

  useEffect(() => {
    validateForm();
  }, [form, validateForm]); // Include validateForm as a dependency

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm((prev) => ({ ...prev, [name]: value }));
    setErrors((prev) => ({ ...prev, [name]: '' })); // Clear errors as user types
  };

  const handleParticipantsChange = (e) => {
    const selectedOptions = Array.from(e.target.selectedOptions).map((option) => option.value);
    setForm((prev) => ({ ...prev, participants: selectedOptions }));
    setErrors((prev) => ({ ...prev, participants: '' })); // Clear errors as user selects
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) return;

    const currentUserId = pb.authStore.model?.id;
    if (!currentUserId) {
      console.error('No user is logged in.');
      return;
    }

    const wagerData = {
      ...form,
      createdby: currentUserId,
    };

    try {
      await addWager(wagerData);
      setForm({
        title: '',
        participants: [],
        status: 'Pending',
        terms: '',
        deadline: '',
        stakes: '',
        outcome: '',
      });
      setErrors({});
      onClose(); // Close the modal after successful submission
    } catch (error) {
      console.error('Error adding wager:', error);
    }
  };

  if (loading) {
    return <p>Loading users...</p>;
  }

  return (
    <div className="container mt-4">
      <h2 className="text-center mb-4">Create a Wager</h2>
      <form onSubmit={handleSubmit} className="card p-4 shadow-sm">
        <div className="mb-3">
          <label htmlFor="title" className="form-label">Wager Title</label>
          <input
            type="text"
            id="title"
            name="title"
            className={`form-control ${errors.title ? 'is-invalid' : ''}`}
            placeholder="Enter wager title"
            value={form.title}
            onChange={handleChange}
          />
          {errors.title && <div className="invalid-feedback">{errors.title}</div>}
        </div>

        <div className="mb-3">
          <label htmlFor="participants" className="form-label">Participants</label>
          <select
            id="participants"
            name="participants"
            className={`form-select ${errors.participants ? 'is-invalid' : ''}`}
            multiple
            value={form.participants}
            onChange={handleParticipantsChange}
          >
            {users.map((user) => (
              <option key={user.id} value={user.id}>
                {user.name || user.email}
              </option>
            ))}
          </select>
          {errors.participants && <div className="invalid-feedback">{errors.participants}</div>}
        </div>

        <div className="mb-3">
          <label htmlFor="status" className="form-label">Status</label>
          <select
            id="status"
            name="status"
            className={`form-select ${errors.status ? 'is-invalid' : ''}`}
            value={form.status}
            onChange={handleChange}
          >
            <option value="Open">Open</option>
            <option value="Settled">Settled</option>
            <option value="Canceled">Canceled</option>
          </select>
          {errors.status && <div className="invalid-feedback">{errors.status}</div>}
        </div>

        <div className="mb-3">
          <label htmlFor="terms" className="form-label">Terms</label>
          <textarea
            id="terms"
            name="terms"
            className={`form-control ${errors.terms ? 'is-invalid' : ''}`}
            placeholder="Enter wager terms"
            value={form.terms}
            onChange={handleChange}
          ></textarea>
          {errors.terms && <div className="invalid-feedback">{errors.terms}</div>}
        </div>

        <div className="mb-3">
          <label htmlFor="deadline" className="form-label">Deadline</label>
          <input
            type="datetime-local"
            id="deadline"
            name="deadline"
            className={`form-control ${errors.deadline ? 'is-invalid' : ''}`}
            value={form.deadline}
            onChange={handleChange}
          />
          {errors.deadline && <div className="invalid-feedback">{errors.deadline}</div>}
        </div>

        <div className="mb-3">
          <label htmlFor="stakes" className="form-label">Stakes</label>
          <textarea
            id="stakes"
            name="stakes"
            className={`form-control ${errors.stakes ? 'is-invalid' : ''}`}
            placeholder="Enter stakes"
            value={form.stakes}
            onChange={handleChange}
          ></textarea>
          {errors.stakes && <div className="invalid-feedback">{errors.stakes}</div>}
        </div>

        <div className="mb-3">
          <label htmlFor="outcome" className="form-label">Outcome</label>
          <textarea
            id="outcome"
            name="outcome"
            className={`form-control ${errors.outcome ? 'is-invalid' : ''}`}
            placeholder="Enter outcome"
            value={form.outcome}
            onChange={handleChange}
          ></textarea>
          {errors.outcome && <div className="invalid-feedback">{errors.outcome}</div>}
        </div>

        <button type="submit" className="btn btn-primary w-100">Add Wager</button>
      </form>
    </div>
  );
}

export default WagerForm;
