import React, { useState } from "react";

function EditWager({ wager, onSave, onCancel }) {
  const [form, setForm] = useState({
    outcome: wager.outcome || "",
    status: wager.status || "Pending",
  });
  const [errors, setErrors] = useState({}); // Store validation errors

  // Handle input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm((prev) => ({ ...prev, [name]: value }));
    setErrors((prev) => ({ ...prev, [name]: "" })); // Clear errors on change
  };

  // Form validation
  const validateForm = () => {
    const newErrors = {};
    if (form.status === "Settled" && !form.outcome) {
      newErrors.outcome = "Outcome is required when status is Settled.";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!validateForm()) return; // Stop submission if validation fails
    onSave(form); // Pass the updated data back to the parent
  };

  return (
    <div className="container mt-4">
      <h2 className="text-center mb-4">Edit Wager</h2>
      <form onSubmit={handleSubmit} className="card p-4 shadow-sm">
        {/* Read-only Fields */}
        <div className="mb-3">
          <label htmlFor="title" className="form-label">
            Title
          </label>
          <input
            type="text"
            id="title"
            className="form-control"
            value={wager.title}
            readOnly
          />
        </div>

        <div className="mb-3">
          <label htmlFor="participants" className="form-label">
            Participants
          </label>
          <textarea
            id="participants"
            className="form-control"
            value={wager.expand?.participants
              ?.map((participant) => participant.name || participant.email)
              .join(", ") || "N/A"}
            readOnly
          ></textarea>
        </div>

        <div className="mb-3">
          <label htmlFor="stakes" className="form-label">
            Stakes
          </label>
          <textarea
            id="stakes"
            className="form-control"
            value={wager.stakes}
            readOnly
          ></textarea>
        </div>

        <div className="mb-3">
          <label htmlFor="deadline" className="form-label">
            Deadline
          </label>
          <input
            type="text"
            id="deadline"
            className="form-control"
            value={new Date(wager.deadline).toLocaleString()}
            readOnly
          />
        </div>

        {/* Editable Fields */}
        <div className="mb-3">
          <label htmlFor="status" className="form-label">
            Status
          </label>
          <select
            id="status"
            name="status"
            className={`form-select ${errors.status ? "is-invalid" : ""}`}
            value={form.status}
            onChange={handleChange}
            required
          >
            <option value="Open">Open</option>
            <option value="Settled">Settled</option>
            <option value="Canceled">Canceled</option>
          </select>
        </div>

        <div className="mb-3">
          <label htmlFor="outcome" className="form-label">
            Outcome
          </label>
          <textarea
            id="outcome"
            name="outcome"
            className={`form-control ${errors.outcome ? "is-invalid" : ""}`}
            placeholder="Enter outcome (if applicable)"
            value={form.outcome}
            onChange={handleChange}
          ></textarea>
          {errors.outcome && (
            <div className="invalid-feedback">{errors.outcome}</div>
          )}
        </div>

        {/* Action Buttons */}
        <div className="d-flex justify-content-between">
          <button
            type="button"
            className="btn btn-secondary"
            onClick={onCancel}
          >
            Cancel
          </button>
          <button type="submit" className="btn btn-success">
            Save
          </button>
        </div>
      </form>
    </div>
  );
}

export default EditWager;
