import React from 'react';
import pb from '../pb';

function Logout({ onLogout }) {
  const handleLogout = () => {
    pb.authStore.clear(); // Clears the token and session data
    onLogout();
  };

  return (
    <div className="d-flex justify-content-end">
      <button onClick={handleLogout} className="btn btn-danger">
        Logout
      </button>
    </div>
  );
}

export default Logout;
